import { environment } from '../../environments/environment';
import { PlanFilesOutput } from '@coverright/data-access/types/medicare';
import { PlanYear } from '@coverright/data-access/medicare';

export function planFiles(files?: PlanFilesOutput | null, year?: PlanYear | null) {
  if (files) {
    const prefix = environment.filesUrl as string + '/';
    const result: PlanFile[] = [];

    for(const key in map) {
      if (files[key as keyof PlanFilesOutput]) {
        result.push({
          label: map[key],
          url: prefix + files[key as keyof PlanFilesOutput]
        })
      }
    }

    return result;
  }
  return [];
}

interface PlanFile {
  url: string,
  label: string
}

const map: {[key: string]: string} = {
  summaryOfBenefitsUrl: 'Summary of Benefits',
  evidenceOfCoverageDocUrl: 'Evidence of Coverage',
  starRatingDescDocUrl: 'CMS star ratings information',
  nonDiscriminationNoticeDocUrl: 'Language Assistance / Non-discrimination Notice',
}
