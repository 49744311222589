import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { createStyles, makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  Collapse,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { CRCheckbox, Tooltip } from '@coverright/ui/inputs';
import {
  Benefit,
  CRSubtitle,
  DoctorCoverageList,
  DrugCoverageList,
  PlanParam,
  SeeMore,
  SimplePlanDetail,
  TooltipedTitle
} from '@coverright/ui/marketplaces';
import { colors } from '@coverright/ui/themes';
import { CRPreloader } from '@coverright/ui/shared';
import {
  areComponentPropsEqual,
  getInitialTooltipByTier,
  GetNextPlansYear,
  GetPlansYear,
  getQuoteId,
  mapDeliveryType
} from '@coverright/utils';
import paths from '../../../../config/router-paths';
import { ComparisonContext } from '../../../ComparisonContext';
import { ListPlanDetail } from '../../../components/ListPlanDetail';
import { PlanTitle } from '../../../components/PlanTitle';
import {
  useCompareYearToYearByClientId,
  useGetEHealthLinkRequest,
  useMedicarePlan,
  useSaveCompareYearToYear
} from '@coverright/data-access/medicare';
import { AdminContext, CompareOfferContext } from '@coverright/shared/contexts';
import { ComparablePlanType, DrugOutput, Plan, PlanYear } from '@coverright/data-access/types/medicare';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import * as _ from 'lodash';
import { MaDrugCostBreakdownModal } from '@coverright/widgets';

export const planStyles = makeStyles((theme: Theme) =>
  createStyles({
    white: {
      color: 'white',
      fontWeight: 700,
    },
    wrapper: {
      minHeight: 717,
      [theme.breakpoints.up('sm')]: {
        minHeight: 540,
      },
    },
    logo: {
      [theme.breakpoints.up('sm')]: {
        height: 33,
        width: 119,
        minWidth: 119,
        marginTop: '4px',
        backgroundPosition: 'right',
      },
      backgroundPosition: 'left',
      height: 50,
      width: 180,
      minWidth: 180,
      marginTop: 0,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    title: {
      textAlign: 'center',
      fontWeight: 500,
      maxWidth: 650,
    },
    card: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 0,
      padding: '10px 0',
      boxShadow: '0px 2px 6px 2px rgba(0, 0, 0, 0.04)',
    },
    infoWrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      marginBottom: '10px',
      [theme.breakpoints.up('sm')]: {
        margin: '0 16px',
      },
    },
    mobileBenefitLabel: {
      width: '50%',
    },
    link: {
      fontSize: 12,
      color: 'rgba(28, 67, 79, 1)',
    },
    enrollSection: {
      padding: '24px 16px 20px',
      margin: '16px 0',
      [theme.breakpoints.up('md')]: {
        maxWidth: 195,
        padding: '34px 16px 20px',
        margin: 0,
      },
      display: 'flex',
      flexDirection: 'column',
      background: '#F3F9FA',
      borderRadius: 10,
      position: 'relative',
    },
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    medium: {
      fontWeight: 500,
    },
    price: {
      fontWeight: 700,
      fontSize: 36,
      marginRight: 20,
      color: 'rgba(28, 67, 79, 1)',
    },
    thinTitle: {
      fontFamily: 'Sailec',
      fontSize: 14,
    },
    checkbox: {
      background: 'transparent',
      padding: 0,
      marginLeft: -8,
    },
    checkboxLabel: {
      marginLeft: 3,
      fontWeight: 500,
      fontSize: 14,
    },
    summaryButton: {
      // border: '1px solid #778E95',
      padding: 4,
      whiteSpace: 'nowrap',
      width: '100%',
      borderRadius: 4,
      '& .MuiButton-label': {
        fontSize: '12px !important',
        //    color: '#778E95'
      },
      /*   '&:hover': {
           border: '2px solid #5ECB9E',
           backgroundColor: 'white',
           '& .MuiButton-label': {
             fontSize: '12px !important',
             color: '#5ECB9E'
           },
         },*/
    },
    enrollButton: {
      fontSize: 14,
      width: '100%',
      padding: 11,
      /*     '&.MuiButton-outlined': {
             border: '1px solid #778E95',
             '& .MuiButton-label': {
               color: '#778E95'
             },
             '&:hover': {
               border: '2px solid #5ECB9E',
               backgroundColor: 'white',
               '& .MuiButton-label': {
                 color: '#5ECB9E'
               },
             },
           }*/
    },
    detailsEnrollButton: {
      padding: '11px 44px',
      whiteSpace: 'nowrap',
    },
    icon: {
      marginLeft: 5,
      transform: 'rotate(0)',
      transition: 'all 0.3s',
    },
    iconOpen: {
      transform: 'rotate(-180deg) !important',
    },
    snp: {
      padding: '5px 0 7px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'rgba(2, 144, 148, 1)',
      marginBottom: -6,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottom: '6px solid white',
    },
    nonCommissionable: {
      padding: '5px 0 7px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: '#950101',
      marginBottom: -6,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      borderBottom: '6px solid white',
    },
    green: {
      color: '#029094',
      opacity: 1,
    },
    red: {
      color: '#DD3D3D',
      opacity: 1,
    },
  })
);

type PlanCardProps = {
  plan: Plan;
  zip: string;
  countyName: string;
  hideEnroll?: boolean;
  onAddClick?: () => void;
  onDetailsToggle?: (value: boolean) => void;
  open?: boolean;
  planYear: PlanYear;
  visible?: boolean;
  authenticated?: boolean;
  preferredDrugs: DrugOutput[];
  favorites: string[];
  toggleFavorite: (bidId: string, zip: string) => void;
};

const tooltipImgProps = {
  width: 12,
  height: 12,
};

function PlanCard({
  plan,
  zip,
  countyName,
  hideEnroll,
  authenticated,
  preferredDrugs,
  onDetailsToggle,
  open: outerOpen,
  planYear,
  favorites,
  toggleFavorite,
}: PlanCardProps) {
  const classes = planStyles();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(outerOpen || false);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [getDetails, { data, loading }] = useMedicarePlan();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));

  /*
  const ref = React.useRef(null);
  const isVisible = useOnScreen(ref);
*/

  const planDetails = React.useMemo(() => {
    return data?.plan;
  }, [data]);

  React.useEffect(() => {
    if (open || outerOpen) {
      if (outerOpen && open !== outerOpen) {
        setOpen(outerOpen);
      }
      getDetails({
        variables: {
          quoteId: getQuoteId(),
          zip,
          countyName,
          bidId: plan.bidId,
        },
      });
    }
  }, [open, outerOpen]);

  return (
    <div data-test={'ma_plan_card'} className={`mt-24 ${classes.wrapper}`}>
      <MaDrugCostBreakdownModal
        bidId={plan.bidId}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        zip={zip}
        countyName={countyName}
        planYear={plan.planYear || GetPlansYear()}
        quoteId={getQuoteId()}
      />
      {!!plan.SNPType && (
        <div className={classes.snp}>
          <TooltipedTitle
            title={'Special Needs Plan (SNP)'}
            labelSx={{ color: 'white', fontWeight: 700 }}
            tooltip={
              'Special Needs Plans (SNPs) are a type of Medicare Advantage plan that cater to beneficiaries with specific special diseases or characteristics. SNPs tailor their plan benefits, provider networks and drug formularies to meet the needs of the specific groups they serve. There are three different types of SNP plans. Dual-SNPs (D-SNP) serve beneficiaries that are eligible for both Medicare and Medicaid. Chronic-SNPs (C-SNP) serve beneficiaries with certain chronic illnesses. Institutional-SNPs (I-SNP) serve beneficiaries who reside in an institution, like a nursing home.'
            }
            imgSrc={'/assets/img/info-white.svg'}
            imgProps={tooltipImgProps}
          />
        </div>
      )}
      {!!plan.nonCommissionable && (
        <div className={classes.nonCommissionable}>
          <TooltipedTitle
            title={'Non commissionable'}
            labelSx={{ color: 'white', fontWeight: 700 }}
            tooltip={''}
          />
        </div>
      )}
      <Card className={classes.card}>
        <div className={'flex mh-10'}>
          <div className={classes.infoWrapper}>
            <PlanTitle
              plan={plan}
              titleHref={
                paths.planDetails + zip + '/' + countyName + '/' + plan.bidId
              }
              inFavorites={favorites.indexOf(plan.bidId) >= 0}
              favoritesClick={() => toggleFavorite(plan.bidId, zip)}
            />
            {phone ? (
              <EnrollSection
                planYear={planYear}
                zip={zip}
                countyName={countyName}
                plan={plan}
                phone={phone}
                preferredDrugs={preferredDrugs}
                onBreakdownClick={() => setModalOpen(true)}
              />
            ) : (
              <Divider sx={{ my: 2 }} />
            )}
            <DetailSection
              zip={zip}
              countyName={countyName}
              plan={plan}
              phone={phone}
              planYear={planYear}
            />
          </div>
          {!phone && (
            <EnrollSection
              planYear={planYear}
              zip={zip}
              countyName={countyName}
              plan={plan}
              phone={phone}
              preferredDrugs={preferredDrugs}
              onBreakdownClick={() => setModalOpen(true)}
            />
          )}
        </div>
        {!phone && (
          <>
            <div className={'mr-10 ml-25'}>
              <Divider sx={{ my: 2 }} />
            </div>
            <div
              className={'pointer flex-column flex-align-center'}
              onClick={() =>
                setOpen((prev) => {
                  if (onDetailsToggle) {
                    onDetailsToggle(!prev);
                  }
                  return !prev;
                })
              }
            >
              <Box sx={{ pb: '6px' }} className={'flex'}>
                <Typography className={'fs-12 lh-12 '}>
                  {open ? 'Hide' : 'Show'} additional details
                </Typography>
                <img
                  src={'/assets/img/green-arrow-small.svg'}
                  className={clsx({
                    [classes.icon]: true,
                    [classes.iconOpen]: open,
                  })}
                />
              </Box>
            </div>
            <Collapse in={open} mountOnEnter>
              {loading && <CRPreloader />}
              {planDetails && (
                <>
                  <div className={'h-16'} />
                  <CRSubtitle
                    sx={{
                      mx: 1,
                      width: 'calc(100% - 16px)',
                      borderRadius: '10px',
                      p: '7px 16px',
                    }}
                    titleClassName={'fs-20'}
                    title={'Additional Details'}
                    icon={'/assets/img/medical-record-1.svg'}
                  />
                  <Box p={'18px 26px 16px'}>
                    <SimplePlanDetail
                      title={'Plan Type'}
                      tooltip={
                        'Plan types include Preferred Provider Organizations (PPO) and Health Maintenance Organization (HMO) plans. PPO plans typically have a wider availability of healthcare providers, but often have higher doctor visit copays. HMO plans require you to select a Primary Care Physician, and you will need a referral to see a specialist. However, copays are typically lower than a PPO plan.'
                      }
                      content={`Medicare Advantage (${planDetails.planType})`}
                    />
                    {!!plan.partBGiveBack && (
                      <SimplePlanDetail
                        title={'Part B Giveback'}
                        tooltip={
                          'A "Part B Give Back" is a feature in some Medicare Advantage plans that may help reduce some or all of the cost of a Medicare recipient\'s Part B premium each month.'
                        }
                        content={
                          <Benefit
                            label={`${plan.partBAmount} per month`}
                            value={!!plan.partBGiveBack}
                          />
                        }
                      />
                    )}
                    <ListPlanDetail
                      title={'Inpatient Hospital Care'}
                      tooltip={
                        'Includes services related to staying in a hospital for scheduled procedures, accidents, or medical emergencies.'
                      }
                      content={planDetails?.hospitalBenefits || []}
                    />
                    {!!planDetails?.drugDetails &&
                      !!planDetails.extraBenefits.drugCoverage && (
                        <>
                          <SimplePlanDetail
                            hideDivider
                            title={'Prescription drug coverage'}
                            tooltip={
                              'Part D (Prescription drug coverage) includes coverage for your prescription drugs. Part D plans can be purchased separately to accompany Original Medicare and Medigap plans. Part D plans are also often bundled with Medicare Advantage plans.'
                            }
                            content={
                              <div>
                                {planDetails?.quoteDrugsTiers?.pharmacyName && (
                                  <Box
                                    display={'flex'}
                                    flexGrow={2}
                                    mb={'15px'}
                                  >
                                    <Benefit
                                      label={
                                        planDetails?.quoteDrugsTiers
                                          ?.pharmacyName +
                                        ` (${mapDeliveryType(
                                          planDetails?.quoteDrugsTiers
                                            ?.deliveryType
                                        )})`
                                      }
                                      value={true}
                                      labelClass={'regular color-primary'}
                                    />
                                  </Box>
                                )}
                                <Button
                                  variant={'outlined'}
                                  size={'small'}
                                  onClick={() =>
                                    navigate(
                                      paths.editPreferences +
                                        zip +
                                        '/' +
                                        countyName +
                                        '/pharmacy'
                                    )
                                  }
                                >
                                  {planDetails?.quoteDrugsTiers?.pharmacyName
                                    ? 'Change'
                                    : 'Add'}{' '}
                                  Pharmacy
                                </Button>
                              </div>
                            }
                          />
                          <div className={'ph-40'}>
                            <ListPlanDetail
                              title={'Drug Deductible'}
                              titleClassName={'fs-12'}
                              imgClassName={'w-15 h-15'}
                              tooltip={
                                'The amount you must pay out of pocket for your prescription drugs before your coverage begins to pay.'
                              }
                              content={[
                                planDetails?.drugDetails?.mrxAltDedAmount || '',
                                planDetails?.drugDetails?.mrxAltNoDedTier || '',
                              ]}
                              hideDivider
                            />
                            {!!planDetails?.quoteDrugsTiers
                              ?.initialCoverageDrugTiers.length && (
                              <>
                                <Typography className={'bold fs-12 mb-16'}>
                                  Preferred retail pharmacy cost sharing for 30
                                  day supply
                                </Typography>
                              </>
                            )}
                            {planDetails?.quoteDrugsTiers?.initialCoverageDrugTiers.map(
                              (item: any) => (
                                <SimplePlanDetail
                                  key={item.tierName}
                                  title={item.tierName}
                                  titleClassName={'fs-12'}
                                  imgClassName={'w-15 h-15'}
                                  tooltip={getInitialTooltipByTier(item.tier)}
                                  hideDivider
                                  content={item.coverage}
                                  thin
                                />
                              )
                            )}
                          </div>
                          <Divider sx={{ my: 3 }} />
                        </>
                      )}
                    <Box mb={2}>
                      <Typography className={'fs-16 bold'}>
                        <strong>
                          Dental, Vision and Hearing Additional Benefits details
                        </strong>
                      </Typography>
                    </Box>
                    {!!planDetails.optionalSupplementalBenefitsItems
                      ?.length && (
                      <ListPlanDetail
                        title={'Optional supplemental benefits'}
                        tooltip={
                          'An added benefit option, available with select plans, that a member may choose to elect in addition to the medical benefits covered by their Medicare Advantage plan.'
                        }
                        content={planDetails.optionalSupplementalBenefitsItems}
                      />
                    )}
                    <ListPlanDetail
                      title={'Dental Services'}
                      tooltip={
                        'Medicare Advantage plans often provide dental benefits at no extra cost. For example: coverage for routine dental exams, cleaning and fluoride treatment. '
                      }
                      content={planDetails?.dental?.benefits || []}
                    />

                    <ListPlanDetail
                      title={'Vision Services'}
                      tooltip={
                        'Medicare Advantage plans often provide vision benefits at no extra cost. For example: coverage for routine eye exams, contact lenses and eyeglasses.'
                      }
                      content={planDetails?.vision?.benefits || []}
                    />

                    <ListPlanDetail
                      title={'Hearing Services'}
                      tooltip={
                        'Medicare Advantage plans often provide built-in hearing benefits for no additional premium. For example: coverage for routine hearing exams and hearing aids.'
                      }
                      content={planDetails?.hearing?.benefits || []}
                    />
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      mt={'24px'}
                      justifyContent={'space-between'}
                    >
                      <CompareCheckbox plan={plan} />
                      <Box display={'flex'} alignItems={'center'}>
                        <Link
                          to={`/plan/${zip}/${countyName}/${plan.bidId}?planYear=${planYear}`}
                          style={{ textDecoration: 'none' }}
                        >
                          <Button className={'w-165'} variant={'outlined'}>
                            Plan details
                          </Button>
                        </Link>
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Collapse>
          </>
        )}
      </Card>
    </div>
  );
}

export default React.memo(PlanCard);

interface EnrollSectionProps {
  phone: boolean;
  plan: Plan;
  zip: string;
  planYear: PlanYear;
  preferredDrugs: DrugOutput[];
  countyName: string;
  onBreakdownClick: any;
}
const EnrollSection = React.memo(
  ({
    phone,
    plan,
    zip,
    countyName,
    planYear,
    preferredDrugs,
    onBreakdownClick,
  }: EnrollSectionProps) => {
    const classes = planStyles();
    const navigate = useNavigate();

    if (!phone) {
      return (
        <div className={classes.enrollSection}>
          <AdminActions plan={plan} zip={zip} countyName={countyName} />
          <div className={'flex-column drug-calculation'}>
            <Tooltip
              placement={'top'}
              arrow
              title={
                'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this page for a full description of the breakdown of these costs.'
              }
            >
              <div>
                <Typography className={'bold fs-36 lh-45'}>
                  {plan.monthlyCost || 'N/A'}
                </Typography>
                <Box
                  display={'flex'}
                  alignItems={'flex-start'}
                  justifyContent={'space-between'}
                >
                  <Typography className={'fs-12 lh-15 bold'}>
                    estimated average total cost per month
                  </Typography>
                  <img
                    className={'w-15 h-15'}
                    src={'/assets/img/info-green.svg'}
                  />
                </Box>
              </div>
            </Tooltip>
            <Box className={'mt-24 flex-space-between'} alignItems={'flex-end'}>
              <Typography className={'fs-12 lh-15'}>
                Monthly premium:
              </Typography>
              <Typography className={'fs-12 lh-15 bold'}>
                {plan.monthlyPremium}
              </Typography>
            </Box>
            <div className={'mt-8 flex-space-between'}>
              <Typography className={'fs-12 lh-15'}>
                Est. drug costs:
              </Typography>
              <Typography className={'fs-12 lh-15 bold'}>
                {plan.drugsCost || 'N/A'}
              </Typography>
            </div>
            <div className={'mt-8 flex-space-between'}>
              <Typography className={'fs-12 lh-15'}>
                Est. doctor costs:
              </Typography>
              <Typography className={'fs-12 lh-15 bold'}>
                {plan.doctorsCost}
              </Typography>
            </div>
            <div className={'mt-30 mb-24'}>
              <Tooltip
                placement={'top'}
                arrow
                title={
                  "The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You still pay the Part B premium, even if you're enrolled in a Medicare Advantage plan. The premium amount you pay may be dependent on your income."
                }
              >
                <Typography
                  sx={{ color: colors.custom.green.variant2 }}
                  className={`fs-12 no-wrap`}
                >
                  Excl. standard Part B premium
                </Typography>
              </Tooltip>
              {plan.optionalSupplementalBenefits && (
                <Tooltip
                  placement={'top'}
                  arrow
                  title={
                    'This plan includes optional benefits you can add to your coverage at an additional cost. See Plan Details for more information.'
                  }
                >
                  <Typography
                    sx={{ color: colors.custom.green.variant2 }}
                    className={`fs-12 mt-5`}
                  >
                    Excl. optional benefits
                  </Typography>
                </Tooltip>
              )}
            </div>
            {!!preferredDrugs?.length && !plan.missingFormularyInAep && (
              <Button
                color={'secondary'}
                size={'small'}
                sx={{ px: '15px!important' }}
                onClick={onBreakdownClick}
                variant={'outlined'}
              >
                Drug cost breakdown
              </Button>
            )}
            {!preferredDrugs?.length && (
              <Button
                color={'secondary'}
                size={'small'}
                sx={{ px: '15px!important' }}
                onClick={() =>
                  navigate(
                    paths.editPreferences + zip + '/' + countyName + '/drugs'
                  )
                }
                variant={'outlined'}
              >
                Add your drugs
              </Button>
            )}
          </div>
          <div className={'mb-20'}>
            <CompareCheckbox plan={plan} />
          </div>
          <div className={'mb-8'}>
            <Link
              to={`/plan/${zip}/${countyName}/${plan.bidId}?planYear=${planYear}`}
              style={{ textDecoration: 'none' }}
            >
              <Button className={classes.enrollButton} variant={'outlined'}>
                Plan details
              </Button>
            </Link>
          </div>
        </div>
      );
    } else {
      return (
        <div className={classes.enrollSection}>
          <div className={'flex-column drug-calculation'}>
            <Tooltip
              placement={'top'}
              arrow
              title={
                'The average total cost per month is equal to the monthly plan premium, plus the estimated average monthly drug cost (based on the drug list you have entered), plus the average cost per month to see primary care and specialist doctors. See the Medicare Disclaimers at the bottom of this page for a full description of the breakdown of these costs.'
              }
            >
              <Box display={'flex'} alignItems={'center'} gap={2}>
                <Typography className={'fs-36 bold'}>
                  {plan.monthlyCost || 'N/A'}
                </Typography>
                <Box
                  display={'flex'}
                  alignItems={'flex-start'}
                  justifyContent={'flex-end'}
                  flex={1}
                >
                  <Typography
                    variant={'body2'}
                    color={'textPrimary'}
                    className={'bold'}
                    align={'right'}
                  >
                    estimated average total cost per month
                  </Typography>
                  <img
                    className={'w-15 h-15 ml-4'}
                    src={'/assets/img/info-green.svg'}
                  />
                </Box>
              </Box>
            </Tooltip>
            <div className={'mt-8 flex-space-between'}>
              <Typography variant={'h5'} color={'textPrimary'}>
                Monthly Premium:
              </Typography>
              <Typography
                variant={'h5'}
                color={'textPrimary'}
                className={'bold'}
              >
                {plan.monthlyPremium}
              </Typography>
            </div>
            <div className={'mt-5 flex-space-between'}>
              <Typography variant={'h5'} color={'textPrimary'}>
                Drug costs:
              </Typography>
              <Typography
                variant={'h5'}
                color={'textPrimary'}
                className={'bold'}
              >
                {plan.drugsCost || 'N/A'}
              </Typography>
            </div>
            <div className={'mt-5 flex-space-between'}>
              <Typography variant={'h5'} color={'textPrimary'}>
                Doctor costs:
              </Typography>
              <Typography
                variant={'h5'}
                color={'textPrimary'}
                className={'bold'}
              >
                {plan.doctorsCost}
              </Typography>
            </div>
            <Box my={2} display={'flex'} flex={1} flexDirection={'column'}>
              <Tooltip
                placement={'top'}
                arrow
                title={
                  "The Part B premium is what most people pay for the Part B Medical coverage provided under Original Medicare. You still pay the Part B premium, even if you're enrolled in a Medicare Advantage plan. The premium amount you pay may be dependent on your income."
                }
              >
                <Typography
                  sx={{ color: colors.custom.green.variant2 }}
                  className={'fs-12 no-wrap'}
                >
                  Excl. standard Part B premium
                </Typography>
              </Tooltip>
              {plan.optionalSupplementalBenefits && (
                <Typography
                  sx={{ color: colors.custom.green.variant2 }}
                  className={`fs-12 mt-5`}
                >
                  Excl. optional benefits
                </Typography>
              )}
            </Box>
            <Box display={'flex'} flex={1}>
              {!!preferredDrugs?.length && (
                <Button
                  color={'secondary'}
                  size={'small'}
                  sx={{ px: '15px!important' }}
                  onClick={onBreakdownClick}
                  variant={'outlined'}
                >
                  Drug cost breakdown
                </Button>
              )}
              {!preferredDrugs?.length && (
                <Button
                  color={'secondary'}
                  size={'small'}
                  sx={{ px: '15px!important' }}
                  onClick={() =>
                    navigate(
                      paths.editPreferences + zip + '/' + countyName + '/drugs'
                    )
                  }
                  variant={'outlined'}
                >
                  Add your drugs
                </Button>
              )}
            </Box>
          </div>
        </div>
      );
    }
  },
  areComponentPropsEqual
);

interface AdminActionsProps {
  plan: Plan;
  zip: string;
  countyName: string;
}

const AdminActions = React.memo(
  ({ plan, countyName, zip }: AdminActionsProps) => {
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const open = Boolean(anchorEl);
    const adminContext = React.useContext(AdminContext);
    const [generateEhealthPlanLink] = useGetEHealthLinkRequest({
      onCompleted: (data) => {
        if (data?.generateEhealthPlanLink) {
          navigator.clipboard.writeText(data?.generateEhealthPlanLink);
        }
      },
    });

    const [getY2Y] = useCompareYearToYearByClientId();
    const [saveY2Y] = useSaveCompareYearToYear();

    const { plans: compareOfferPlans, togglePlan: toggleCompareOffer } =
      React.useContext(CompareOfferContext);

    const handleClose = () => {
      setAnchorEl(null);
    };

    const copyEHealthLink = () => {
      generateEhealthPlanLink({
        variables: {
          bidId: plan.bidId,
          countyName,
          zip,
          planYear: plan.planYear,
        },
      });
    };

    const addToY2Y = () => {
      if (adminContext?.clientId) {
        getY2Y({ variables: { clientId: adminContext?.clientId } }).then(
          (res) => {
            const y2y = res.data?.compareYearToYearByClientId;
            return saveY2Y({
              variables: {
                input: {
                  ...({
                    ..._.omit(y2y, 'isLocked'),
                    createdAt: undefined,
                    updatedAt: undefined,
                  } || {}),
                  suggestedMaPlanId: plan.bidId,
                  currentYear: GetPlansYear(),
                  zip,
                  county: countyName,
                  clientId: adminContext?.clientId,
                },
              },
            });
          }
        );
      }
    };

    if (!adminContext) {
      return null;
    }

    return (
      <>
        <IconButton
          sx={{ position: 'absolute', right: '5px', top: '5px' }}
          size="small"
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          <MoreVertIcon fontSize="inherit" />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              toggleCompareOffer({
                bidId: plan.bidId,
                type: ComparablePlanType.Ma,
                county: countyName,
                zip,
                planYear: plan.planYear,
              });
              handleClose();
            }}
          >
            {compareOfferPlans.some(
              (o) =>
                o.bidId === plan.bidId &&
                (!o.planYear || o.planYear === plan.planYear)
            )
              ? 'Remove from'
              : 'Add to'}{' '}
            compare offer
          </MenuItem>
          {plan.planYear === GetNextPlansYear() && (
            <MenuItem
              onClick={() => {
                addToY2Y();
                handleClose();
              }}
            >
              Add to Y2Y
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              copyEHealthLink();
              handleClose();
            }}
          >
            E Health
          </MenuItem>
        </Menu>
      </>
    );
  },
  areComponentPropsEqual
);

interface DetailSectionProps {
  phone: boolean;
  plan: Plan;
  zip: string;
  planYear: PlanYear;
  countyName: string;
}

const DetailSection = React.memo(
  ({ phone, plan, zip, countyName, planYear }: DetailSectionProps) => {
    const classes = planStyles();
    const navigate = useNavigate();
    const [doctorCostsExpanded, setDoctorCostsExpanded] = React.useState(false);

    if (!phone) {
      return (
        <div className={'flex'}>
          <div className={'flex-column'}>
            <TooltipedTitle
              title={'Monthly Plan Premium'}
              tooltip={
                'This is the fixed monthly amount charged by the insurance company to maintain insurance coverage through the plan. Many Medicare Advantage plans have a $0 monthly premium.'
              }
              imgProps={tooltipImgProps}
            />
            <PlanParam
              label={''}
              className={'mt-5'}
              value={plan.monthlyPremium || '$0'}
            />
            <Divider sx={{ my: 3 }} />
            <TooltipedTitle
              title={'Deductibles'}
              tooltip={
                'The amount you must pay out of pocket for your health care before your coverage begins to pay. For example, in Original Medicare, you pay a new deductible for each benefit period for Part A, and each year for Part B. These amounts can change every year.'
              }
              imgProps={tooltipImgProps}
            />
            <div className={'flex mt-12'}>
              <div className={'flex-column'}>
                <PlanParam
                  label={'Medical'}
                  value={`${plan?.inNetworkDeductableAmount || '$0'}`}
                />
              </div>
              <div className={'flex-column'}>
                <PlanParam
                  label={'Drug'}
                  value={`${plan?.drugDetails?.mrxAltDedAmountShort || 'N/A'}`}
                />
              </div>
            </div>
            <div className={'mt-24'}>
              <TooltipedTitle
                title={'Max out-of-pocket'}
                tooltip={
                  'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'
                }
                imgProps={tooltipImgProps}
              />
            </div>
            <div className={'flex mt-10'}>
              <div className={'flex-column'}>
                <PlanParam
                  label={'In network'}
                  value={`${plan.outOfPocketAmount}`}
                />
              </div>
              <div className={'flex-column'}>
                {!!plan.maxEnrollmentAmount && (
                  <PlanParam
                    label={'Out-of-network'}
                    value={`${plan.maxEnrollmentAmount}`}
                  />
                )}
              </div>
            </div>
            <Divider sx={{ my: 3 }} />
            <TooltipedTitle
              title={'Doctor & Hospital Costs'}
              tooltip={
                'Primary and Specialist doctor copays are the amount you can expect to pay for a regular visit to your primary care physician or a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details. Hospital cost is the total amount you will pay under this plan for a 7 day inpatient hospital stay.'
              }
              labelSx={{ whiteSpace: 'nowrap' }}
              imgProps={tooltipImgProps}
            />
            <PlanParam
              label={'Primary doctor (In-network)'}
              className={'mt-10'}
              value={plan?.primaryCarePhysicianShort || 'N/A'}
            />
            <PlanParam
              label={'Specialist (In-network)'}
              className={'mv-15'}
              value={plan.physicianSpecialistShort.join(',')}
            />
            <PlanParam
              label={'Hospital (In-network)'}
              value={plan.firstWeekOfStay || 'N/A'}
            />
          </div>
          <div className={'flex-column ml-20'}>
            <TooltipedTitle
              title={'Your Doctors'}
              tooltip={
                "Coverage of your doctors under this plan. In-network and out-of network (if applicable) doctor costs will be subject to the copays or coinsurance outlined as per this plan's policy."
              }
              imgProps={tooltipImgProps}
            />
            {!!plan.doctorsCoverage.length && (
              <DoctorCoverageList
                isPPO={plan.planType === 'PPO'}
                coverage={plan.doctorsCoverage}
              />
            )}
            {!plan.doctorsCoverage.length && (
              <div>
                <Button
                  variant={'outlined'}
                  color={'secondary'}
                  sx={{ mt: 1, px: '24px!important' }}
                  size={'small'}
                  onClick={() =>
                    navigate(paths.editPreferences + zip + '/' + countyName)
                  }
                >
                  Add doctors
                </Button>
              </div>
            )}
            <Divider sx={{ my: 3 }} />
            <div
              onClick={() =>
                window.open(
                  document.location.origin +
                    '/summary/' +
                    zip +
                    '/' +
                    plan.bidId
                )
              }
            >
              <TooltipedTitle
                title={'Your Drugs'}
                tooltip={
                  "Coverage of your prescription drugs under this plan. Covered drugs will be subject to payments of the retail cost up until you meet your deductible, and then copayments or coinsurance based on the drug's tier according to plan policy details."
                }
                imgProps={tooltipImgProps}
              />
            </div>
            {plan.missingFormularyInAep && (
              <Typography className={'fs-12 card-title'} color={'textPrimary'}>
                Speak to an Agent for 2023 drug details for this plan
              </Typography>
            )}
            {!plan.missingFormularyInAep && (
              <>
                {!!plan.drugsCoverage.length && (
                  <DrugCoverageList coverage={plan.drugsCoverage} />
                )}
                {!plan.drugsCoverage.length && (
                  <div>
                    <Button
                      variant={'outlined'}
                      color={'secondary'}
                      sx={{ mt: 1, px: '24px!important' }}
                      size={'small'}
                      onClick={() =>
                        navigate(
                          paths.editPreferences +
                            zip +
                            '/' +
                            countyName +
                            '/drugs'
                        )
                      }
                    >
                      Add drugs
                    </Button>
                  </div>
                )}
              </>
            )}
          </div>
          <div className={'flex-column ml-20'}>
            <TooltipedTitle
              title={'Prescription coverage'}
              tooltip={
                'Indicates whether this plan also includes bundled prescription drug coverage.'
              }
              imgProps={tooltipImgProps}
            />
            <div className={'mv-5'}>
              <Benefit
                label={plan.extraBenefits.drugCoverage ? 'Yes' : 'No'}
                value={plan.extraBenefits.drugCoverage}
              />
            </div>
            <TooltipedTitle
              title={'Part B Premium Reduction'}
              labelSx={{ width: '95px' }}
              tooltip={
                'A Part B premium reduction benefit is offered by some Medicare Part C (Medicare Advantage) plans.\n\nIf you enroll in a Medicare Advantage plan with this benefit, the insurance company will help pay some or all of your Part B monthly premium. The amount covered can range from 10 cents to the full Part B premium cost.'
              }
              imgProps={tooltipImgProps}
            />
            <div className={'mt-5'}>
              <Benefit
                label={plan.partBGiveBack ? `${plan.partBAmount}` : 'No'}
                value={!!plan.partBGiveBack}
              />
            </div>

            <Divider sx={{ my: 2 }} />
            <TooltipedTitle
              title={'Extra benefits'}
              tooltip={
                'Many Medicare Advantage plans offer additional vision, hearing and dental benefits at no extra cost, such as eye exams and eye glasses, hearing aids and dentures.'
              }
              imgProps={tooltipImgProps}
            />
            <div className={'mt-8'}>
              <Benefit
                labelClass={
                  plan.extraBenefits.dental ? classes.green : classes.red
                }
                label={'Dental'}
                value={plan.extraBenefits.dental}
              />
            </div>
            <div className={'mt-8'}>
              <Benefit
                labelClass={
                  plan.extraBenefits.vision ? classes.green : classes.red
                }
                label={'Vision'}
                value={plan.extraBenefits.vision}
              />
            </div>
            <div className={'mt-8'}>
              <Benefit
                labelClass={
                  plan.extraBenefits.hearing ? classes.green : classes.red
                }
                label={'Hearing'}
                value={plan.extraBenefits.hearing}
              />
            </div>
            <div className={'mt-8'}>
              <Benefit
                labelClass={
                  plan.extraBenefits.fitness ? classes.green : classes.red
                }
                label={'Fitness'}
                value={plan.extraBenefits.fitness}
              />
            </div>
            <div className={'mt-8'}>
              <Benefit
                labelClass={
                  plan.extraBenefits.insulinSavings
                    ? classes.green
                    : classes.red
                }
                label={'Insulin savings'}
                value={plan.extraBenefits.insulinSavings}
              />
            </div>
            <div className={'mt-8'}>
              <Benefit
                labelClass={
                  plan.extraBenefits.overTheCounter
                    ? classes.green
                    : classes.red
                }
                label={'OTC benefits'}
                value={plan.extraBenefits.overTheCounter}
              />
            </div>
            <div className={'mt-8'}>
              <Benefit
                labelClass={
                  plan.extraBenefits.telehealth ? classes.green : classes.red
                }
                label={'Telehealth'}
                value={plan.extraBenefits.telehealth}
              />
            </div>
            <div className={'mt-8'}>
              <Benefit
                labelClass={
                  plan.extraBenefits.transportation
                    ? classes.green
                    : classes.red
                }
                label={'Transportation'}
                value={plan.extraBenefits.transportation}
              />
            </div>
            {/*plan.otherDefinedSupplemental && (  WAITING FOR API
                  <Typography variant={'h5'}
                              color={'textSecondary'}
                              className={'mt-8'}><i>Some extra benefits require an additional premium (see Plan details for more information)</i></Typography>
                )*/}
          </div>
        </div>
      );
    } else {
      return (
        <Box sx={{ px: '6px' }} className={'flex-column'}>
          <div className={'flex'}>
            <div className={'flex-column'}>
              <TooltipedTitle
                title={'Deductibles'}
                tooltip={
                  'The amount you must pay out of pocket for your health care before your coverage begins to pay. For example, in Original Medicare, you pay a new deductible for each benefit period for Part A, and each year for Part B. These amounts can change every year.'
                }
                imgProps={tooltipImgProps}
              />
              <PlanParam
                className={'mt-12'}
                label={'Medical'}
                value={`${plan?.inNetworkDeductableAmount || '$0'}`}
              />
              <PlanParam
                className={'mt-12'}
                label={'Drug'}
                value={`${plan?.drugDetails?.mrxAltDedAmount || 'N/A'}`}
              />
            </div>
            <div className={'flex-column'}>
              <TooltipedTitle
                title={'Max out-of-pocket'}
                tooltip={
                  'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each plan period. This includes copays, coinsurance amounts, hospital visits and other healthcare-related services. Once you reach this limit, the health plan pays 100% of your healthcare costs. The limit does not apply to premiums, balance-billed charges from out of network providers, or other services that are not covered by the plan.'
                }
                imgProps={tooltipImgProps}
              />
              <PlanParam
                className={'mt-12'}
                label={'In network'}
                value={`${plan.outOfPocketAmount} p.a.`}
              />
              {!!plan.maxEnrollmentAmount && (
                <PlanParam
                  className={'mt-12'}
                  label={'Out-of-network'}
                  value={`${plan.maxEnrollmentAmount} p.a.`}
                />
              )}
            </div>
          </div>
          <Divider sx={{ my: 2 }} />
          <div className={'flex'}>
            <div className={'flex-column'}>
              <TooltipedTitle
                title={'Doctor & Hospital Costs'}
                tooltip={
                  'Primary and Specialist doctor copays are the amount you can expect to pay for a regular visit to your primary care physician or a medical specialist (such as an orthopedist, dermatologist or cardiologist), as outlined in your policy details. Hospital cost is the total amount you will pay under this plan for a 7 day inpatient hospital stay.'
                }
                imgProps={tooltipImgProps}
              />
              <PlanParam
                label={'Primary doctor (In-network)'}
                className={'mt-12'}
                labelClassName={classes.mobileBenefitLabel}
                value={plan?.primaryCarePhysicianShort || 'N/A'}
              />
              {doctorCostsExpanded && (
                <>
                  <PlanParam
                    label={'Specialist (In-network)'}
                    className={'mt-12'}
                    labelClassName={classes.mobileBenefitLabel}
                    value={plan.physicianSpecialistShort.join(',')}
                  />
                  <PlanParam
                    label={'Hospital (In-network)'}
                    className={'mt-12'}
                    labelClassName={classes.mobileBenefitLabel}
                    value={plan.firstWeekOfStay || 'N/A'}
                  />
                </>
              )}
              <SeeMore
                sx={{ mt: 1 }}
                expanded={doctorCostsExpanded}
                onToggle={(value) => setDoctorCostsExpanded(!value)}
              />
            </div>
          </div>
          <Divider sx={{ my: '12px' }} />
          <div className={'flex'}>
            <div className={'mb-4 flex-column'}>
              <TooltipedTitle
                title={'Your Doctors'}
                tooltip={
                  "Coverage of your doctors under this plan. In-network and out-of network (if applicable) doctor costs will be subject to the copays or coinsurance outlined as per this plan's policy."
                }
                imgProps={tooltipImgProps}
              />
              {!!plan.doctorsCoverage.length && (
                <DoctorCoverageList
                  isPPO={plan.planType === 'PPO'}
                  coverage={plan.doctorsCoverage}
                />
              )}
              {!plan.doctorsCoverage.length && (
                <div>
                  <Button
                    variant={'outlined'}
                    color={'secondary'}
                    sx={{ mt: 1, px: '24px!important' }}
                    size={'small'}
                    onClick={() =>
                      navigate(paths.editPreferences + zip + '/' + countyName)
                    }
                  >
                    Add doctors
                  </Button>
                </div>
              )}
            </div>
            <div className={'mb-4 flex-column'}>
              <div
                onClick={() =>
                  window.open(
                    document.location.origin +
                      '/summary/' +
                      zip +
                      '/' +
                      plan.bidId
                  )
                }
              >
                <TooltipedTitle
                  title={'Your Drugs'}
                  tooltip={
                    "Coverage of your prescription drugs under this plan. Covered drugs will be subject to payments of the retail cost up until you meet your deductible, and then copayments or coinsurance based on the drug's tier according to plan policy details."
                  }
                  imgProps={tooltipImgProps}
                />
              </div>
              {plan.missingFormularyInAep && (
                <Typography
                  className={'fs-12 card-title'}
                  color={'textPrimary'}
                >
                  Speak to an Agent for 2023 drug details for this plan
                </Typography>
              )}
              {!plan.missingFormularyInAep && (
                <>
                  {!!plan.drugsCoverage.length && (
                    <DrugCoverageList coverage={plan.drugsCoverage} />
                  )}
                  {!plan.drugsCoverage.length && (
                    <div>
                      <Button
                        variant={'outlined'}
                        color={'secondary'}
                        sx={{ mt: 1, px: '24px!important' }}
                        size={'small'}
                        onClick={() =>
                          navigate(
                            paths.editPreferences +
                              zip +
                              '/' +
                              countyName +
                              '/drugs'
                          )
                        }
                      >
                        Add drugs
                      </Button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <Divider sx={{ my: '12px' }} />
          <TooltipedTitle
            title={'Prescription coverage'}
            tooltip={
              'Indicates whether this plan also includes bundled prescription drug coverage.'
            }
            imgProps={tooltipImgProps}
          />
          <div className={'mv-5'}>
            <Benefit
              label={plan.extraBenefits.drugCoverage ? 'Yes' : 'No'}
              value={plan.extraBenefits.drugCoverage}
            />
          </div>
          <div className={'h-12'} />
          <TooltipedTitle
            title={'Part B Premium Reduction'}
            tooltip={
              'A Part B premium reduction benefit is offered by some Medicare Part C (Medicare Advantage) plans.\n\nIf you enroll in a Medicare Advantage plan with this benefit, the insurance company will help pay some or all of your Part B monthly premium. The amount covered can range from 10 cents to the full Part B premium cost.'
            }
            imgProps={tooltipImgProps}
          />
          <div className={'mt-5'}>
            <Benefit
              label={plan.partBGiveBack ? `${plan.partBAmount}` : 'No'}
              value={!!plan.partBGiveBack}
            />
          </div>

          <Divider sx={{ my: '12px' }} />
          <TooltipedTitle
            title={'Extra benefits'}
            tooltip={
              'Many Medicare Advantage plans offer additional vision, hearing and dental benefits at no extra cost, such as eye exams and eye glasses, hearing aids and dentures.'
            }
            imgProps={tooltipImgProps}
          />
          <div className={'flex'}>
            <div className={'flex-column'}>
              <div className={'mt-8'}>
                <Benefit label={'Dental'} value={plan.extraBenefits.dental} />
              </div>
              <div className={'mt-8'}>
                <Benefit label={'Vision'} value={plan.extraBenefits.vision} />
              </div>
              <div className={'mt-8'}>
                <Benefit label={'Hearing'} value={plan.extraBenefits.hearing} />
              </div>
              <div className={'mt-8'}>
                <Benefit label={'Fitness'} value={plan.extraBenefits.fitness} />
              </div>
            </div>
            <div className={'flex-column'}>
              <div className={'mt-8'}>
                <Benefit
                  label={'Insulin savings'}
                  value={plan.extraBenefits.insulinSavings}
                />
              </div>
              <div className={'mt-8'}>
                <Benefit
                  label={'OTC benefits'}
                  value={plan.extraBenefits.overTheCounter}
                />
              </div>
              <div className={'mt-8'}>
                <Benefit
                  label={'Telehealth'}
                  value={plan.extraBenefits.telehealth}
                />
              </div>
              <div className={'mt-8'}>
                <Benefit
                  label={'Transportation'}
                  value={plan.extraBenefits.transportation}
                />
              </div>
            </div>
            {/*plan.otherDefinedSupplemental && (  WAITING FOR API
                  <Typography variant={'h5'}
                              color={'textSecondary'}
                              className={'mt-8'}><i>Some extra benefits require an additional premium (see Plan details for more information)</i></Typography>
                )*/}
          </div>
          <Divider sx={{ my: 2 }} />
          <div>
            <div className={'mb-8'}>
              <CompareCheckbox plan={plan} />
            </div>
            <div className={'flex'}>
              <div className={'mr-35 flex-column'}>
                <Link
                  to={`/plan/${zip}/${countyName}/${plan.bidId}?planYear=${planYear}`}
                  style={{ textDecoration: 'none' }}
                >
                  <Button className={classes.enrollButton} variant={'outlined'}>
                    Plan details
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Box>
      );
    }
  },
  areComponentPropsEqual
);

const CompareCheckbox = ({ plan }: { plan: Plan }) => {
  const { addToCompare, isInCompare, removeFromCompare } =
    React.useContext(ComparisonContext);
  const classes = planStyles();
  return (
    <CRCheckbox
      variant={'standart'}
      controlClassName={classes.checkbox}
      labelClassName={'fs-14 ls-22 no-wrap medium'}
      label={'Add to compare'}
      checked={isInCompare(plan)}
      onChange={(e, checked) => {
        checked ? addToCompare(plan) : removeFromCompare(ComparablePlanType.Ma, plan.bidId);
      }}
    />
  );
};
