import React from 'react';
import { Box, Button, Card, Divider, IconButton, Typography } from '@mui/material';
import { colors } from '@coverright/ui/themes';
import * as Scroll from 'react-scroll';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { PlanWithTiers } from '@coverright/data-access/medicare';
import { PlanYear } from '@coverright/data-access/types/medicare';
import _ from 'lodash';

const scrollSpy = Scroll.scrollSpy;
const Link = Scroll.Link;

export default function PlanDetailsContent({plan}: {plan?: PlanWithTiers}) {
  const [collapsed, setCollapsed] = React.useState(false);

  useScrollPosition(({currPos}) => {
    if( currPos.y < -250 && !collapsed) {
      setCollapsed(true);
    }
    if( currPos.y >= -150 && collapsed) {
      setCollapsed(false);
    }
  }, [collapsed])


  React.useEffect(() => {
    scrollSpy.update();
  }, []);

  const titles = React.useMemo(() => {
    const result = [
      "Key Plan Benefits Overview",
      "Drug Coverage & Costs",
      "Medical & Hospital Coverage",
      "Extra Benefits",
      "Plan Brochures",
    ];
    if (plan && (!plan.extraBenefits.drugCoverage || !plan.drugDetails)) {
      result.splice(1, 1)
    }

    return result;
  }, [plan]);



  return <Box sx={{position: 'sticky', top: 0}}>
    <Card sx={{p: 3}}>
      <Typography className={'bold fs-16 lh-20'}>Table Of Contents</Typography>
      <Divider sx={{my: 2}} />
      {plan && titles.map((title, idx) => <PlanDetailTitle key={idx} title={title} index={idx + 1} />)}
    </Card>
  </Box>
}

const PlanDetailTitle = ({title, index}: { title: string, index: number}) => {
  return (<>
    <Link activeClass="activeMenu" to={title} smooth={true} spy={true} duration={200}>
      <Box sx={{
        fontSize: '16px',
        fontWeight: 700,
        p: '12px',
        cursor: 'pointer',
        color: colors.text.secondary,
        whiteSpace: 'nowrap'
      }}>
        {index}. {title}
      </Box>
    </Link>
  </>)
}
