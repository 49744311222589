import React from 'react';
import { useParams } from 'react-router';
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Button, Theme, Typography } from '@mui/material';
import { PlanFilterContext } from './filters/PlanFilterContext';
import { getQuoteId } from '@coverright/utils';
import { TextInput } from '@coverright/ui/inputs';
import { QuoteContext } from '@coverright/shared/contexts';
import { RequestStatusDto } from '@coverright/data-access/types/medicare';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bold: {
      fontWeight: 700,
    },
    divider: {
      color: '#1c434f14',
    },
    input: {
      marginBottom: 16,
      '& input': {
        padding: '10px 12px 6px 13px!important',
      }
    },
    checkbox: {
      padding: 0,
      marginLeft: -8,
    },
    label: {
      fontSize: 11,
    },
    placeholder: {
      '& p': {
        fontSize: 12,
      }
    },
    inputLabel: {
      fontSize: 14,
    }
  }),
);

const MonthlyCostCalculator = () => {
  const classes = useStyles();
  const {zip, countyName} = useParams<{zip: string, countyName: string}>();
  const {doctorVisitsNumber, specialistVisitsNumber, save} = React.useContext(QuoteContext);
  const {refresh} = React.useContext(PlanFilterContext)
  const [doctorVisits, setDoctorVisits] = React.useState<string>('');
  const [specialistVisits, setSpecialistVisits] = React.useState<string>('');

  React.useEffect(() => {
    if (typeof doctorVisitsNumber !== 'undefined' && doctorVisitsNumber !== null) {
      setDoctorVisits(doctorVisitsNumber.toString())
    }
  }, [doctorVisitsNumber])

  React.useEffect(() => {
    if (typeof specialistVisitsNumber !== 'undefined' && specialistVisitsNumber !== null) {
      setSpecialistVisits(specialistVisitsNumber.toString())
    }
  }, [specialistVisitsNumber])

  const onClick = () => {
    save({
      variables: {
        data: {
          zip,
          county: countyName,
          doctorVisitsNumber: isNaN(parseFloat(doctorVisits)) ? undefined : parseFloat(doctorVisits),
          specialistVisitsNumber: isNaN(parseFloat(specialistVisits)) ? undefined : parseFloat(specialistVisits),
          status: RequestStatusDto.InProgress,
          id: getQuoteId()
        }
      }
    }).then(refresh);
  }

  return <div className={'monthly-cost-calculator'}>
    <Typography className={'bold fs-20 lh-25'}>Doctor cost calculator</Typography>
    <Typography className={'fs-11 lh-14 mt-8'} color={'textSecondary'}>Your estimated monthly copay costs will change based on the amounts you enter below. We have included prescription information that you’ve already entered.</Typography>
        <Box mt={2}>
      <div className={'flex flex-align-center relative'}>
        <TextInput label={'Primary care visits'}
                   className={classes.input}
                   inputLabelClass={classes.inputLabel}
                   value={doctorVisits}
                   wrapperSx={{display: 'flex', gap: 1, alignItems: 'center'}}
                   sx={{width: 55, mb: 0}}
                   hideTick
                   fullWidth={false}
                   onChange={event => setDoctorVisits(event.target.value)}
                   type={'number'}
                   name={'primary-care-visits'}
        />
        <Box position={'absolute'} right={0} top={10}>
          <Typography color={'textPrimary'} className={'fs-12'}>per year</Typography>
        </Box>
      </div>
      <div className={'flex flex-align-center relative'}>
        <TextInput label={'Specialist visits'}
                   className={classes.input}
                   inputLabelClass={classes.inputLabel}
                   value={specialistVisits}
                   wrapperSx={{display: 'flex', gap: 1, alignItems: 'center'}}
                   sx={{width: 55, mb: 0}}
                   hideTick
                   fullWidth={false}
                   onChange={event => setSpecialistVisits(event.target.value)}
                   name={'specialist-visits'}
        />
        <Box position={'absolute'} right={0} top={10}>
          <Typography color={'textPrimary'} className={'fs-12'}>per year</Typography>
        </Box>
      </div>
      <Box mt={'4px'}>
        <Button onClick={onClick} variant={'outlined'} fullWidth>Update doctor costs</Button>
      </Box>
    </Box>
  </div>
}

export default React.memo(MonthlyCostCalculator);
