import React from "react";
import clsx from 'clsx';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import { areComponentPropsEqual, getCompanyLogo } from '@coverright/utils';
import { Link } from 'react-router-dom';
import PlanRating from './PlanRating';
import { Plan } from '@coverright/data-access/types/medicare';

interface PlanTitleProps {
  plan: Plan,
  showBid?: boolean,
  className?: string,
  inFavorites?: boolean,
  hideFavorites?: boolean,
  favoritesClick?: () => void,
  titleHref?: string,
}

const useStyles = (theme: any) =>  ({
  logo: {
    [theme.breakpoints.up('sm')]: {
      height: 33,
      width: 119,
      minWidth: 119,
      marginTop: '4px',
      backgroundPosition: 'right'
    },
    backgroundPosition: 'left',
    height: 50,
    width: '50%',
    minWidth: 120,
    marginTop: 0,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
  }
})

function Func({plan, className, showBid, inFavorites, favoritesClick, hideFavorites, titleHref}: PlanTitleProps) {
  const theme = useTheme();
  const classes = useStyles(theme);
  const phone = useMediaQuery(theme.breakpoints.down('sm'));

  const Desktop = () => <Box sx={{mt: '14px'}} flex={1} className={clsx('flex', className)}>
    <div className={'flex-column'}>
      <div className={'flex-space-between'}>
        <PlanRating plan={plan} />
        {!hideFavorites && <div className={'flex flex-align-center pointer'} onClick={favoritesClick}>
          {!inFavorites && <>
            <Typography className={'fs-11 medium no-wrap'} color={'textPrimary'}>Add to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/assets/img/heart.svg'}/>
          </>}
          {inFavorites && <>
            <Typography className={'fs-11 medium no-wrap'} color={'textPrimary'}>Added to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/assets/img/heart-fill.svg'}/>
          </>}
        </div>}
      </div>
      <div className={'flex-space-between'}>
        <div className={'mt-8'}>
          <Link to={titleHref || '#'} className={'fs-20 lh-25 text-primary bold pointer'}>{plan.planName}</Link>
        </div>
        <div>
          <Box sx={{...classes.logo, backgroundImage: `url("${getCompanyLogo(plan.parentOrgName, plan.orgName)}")`}}  />
        </div>
      </div>
    </div>
  </Box>

  const Mobile = () => <div className={'flex-column'}>
    <div className={'flex-space-between'}>
      <Box sx={{...classes.logo, backgroundImage: `url("${getCompanyLogo(plan.parentOrgName, plan.orgName)}")`}}  />
      <div className={'flex-column flex-align-end'}>
        {!hideFavorites && <div className={'flex flex-align-center pointer'} onClick={favoritesClick}>
          {!inFavorites && <>
            <Typography className={'fs-11 medium no-wrap'}>Add to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/assets/img/heart.svg'}/>
          </>}
          {inFavorites && <>
            <Typography className={'fs-11 medium no-wrap'}>Added to favorites</Typography>
            <div className={'w-5'} />
            <img width={20} height={20} src={'/assets/img/heart-fill.svg'}/>
          </>}
        </div>}
        <PlanRating plan={plan} />
      </div>
    </div>
    <div className={'flex-space-between'}>
      <Box display={'flex'} flex={1} className={'mt-8'}>
        <Link to={titleHref || '#'} className={'fs-20 lh-25 text-primary bold pointer'}>{plan.planName}</Link>
      </Box>
      <div>

      </div>
    </div>
  </div>

  return <div className={clsx('flex', className)}>
    {phone ? <Mobile /> : <Desktop/>}
  </div>
}

export const PlanTitle = React.memo(Func, areComponentPropsEqual)
