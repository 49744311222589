import {MutationHookOptions, useMutation} from "@apollo/client";
import {gql} from "@apollo/client";
import {
  MutationCreateMedicareQuoteForClientArgs,
  SaveMedicareQuoteOutput
} from '@coverright/data-access/types/medicare';

export function useCreateMedicareQuoteForClient(options?: MutationHookOptions<any, MutationCreateMedicareQuoteForClientArgs>) {
  return useMutation<{createMedicareQuoteForClient: SaveMedicareQuoteOutput}, MutationCreateMedicareQuoteForClientArgs>(gql(mutation), {
    ...options
  })
}

const mutation = `
mutation ($clientId: UUID!, $data: MedicareQuoteInput!, $agentOffer: Boolean) {
  createMedicareQuoteForClient(clientId: $clientId, data: $data, agentOffer: $agentOffer) {
    clientId
    maQuoteID
  }
}
`;
