import React from "react";
import { Box, Card } from '@mui/material';
import MonthlyCostCalculator from '../plan-list/components/MonthlyCostCalculator';
import HealthcarePreferences from '../plan-list/components/HealthcarePreferences';

const FavoritesSideContent = () => {

  return <Box sx={{minWidth: 304, maxWidth: { lg: 304 }, display: 'flex', flexDirection: 'column', gap: '24px'}}>
    <Card sx={{p: 3, mt: 3}}>
      <HealthcarePreferences />
    </Card>
    <Card sx={{p: 3}}>
      <MonthlyCostCalculator />
    </Card>
  </Box>
}

export default FavoritesSideContent;
