import React from "react";
import MonthlyCostCalculator from "./MonthlyCostCalculator";
import { Box, Card } from '@mui/material';
import PlanFilter from './filters/PlanFilter';
import HealthcarePreferences from './HealthcarePreferences';

const PlanListSideContent = () => {
  return <Box sx={{minWidth: 304, maxWidth: { lg: 304 }, display: 'flex', flexDirection: 'column'}}>
    <Card sx={{p: 3, mt: {xs: '12px', lg: 0}}}>
      <PlanFilter />
    </Card>
    <Card sx={{p: 3, mt: 3}}>
      <HealthcarePreferences />
    </Card>
    <Card sx={{p: 3, mt: 3}}>
      <MonthlyCostCalculator />
    </Card>
  </Box>
}

export default PlanListSideContent;
