import React from 'react';
import { useSnackbar } from 'notistack';
import { Box, CircularProgress, IconButton, Typography } from '@mui/material';
import { setQuoteId } from '@coverright/utils';
import { convertPreferredDrugInput } from '@coverright/widgets';
import LoopRoundedIcon from '@mui/icons-material/LoopRounded';
import useFiltersUpdating from '../hooks/useFiltersUpdating';
import { KeycloakContext, KeycloakRoles } from '@coverright/shared/keycloak';
import { useCreateMedicareQuoteForClient } from '@coverright/data-access/medicare';
import {
  AdminContext,
  CompareOfferContext,
  QuoteContext,
} from '@coverright/shared/contexts';
import { ClientDrugDeliveryType } from '@coverright/data-access/types/enrollment';
import { environment } from '../../environments/environment';

const AdminNotification = () => {
  const { isAdmin } = React.useContext(KeycloakContext);
  const adminContext = React.useContext(AdminContext);
  const compareOfferContext = React.useContext(CompareOfferContext);
  const quoteContext = React.useContext(QuoteContext);
  const [create] = useCreateMedicareQuoteForClient();
  const { enqueueSnackbar } = useSnackbar();
  const { filtersUpdating } = useFiltersUpdating();

  const createOffer = React.useCallback(() => {
    if (adminContext) {
      create({
        variables: {
          clientId: adminContext.clientId,
          agentOffer: true,
          data: {
            preferredDoctors: quoteContext.preferredDoctors?.map((d: any) => ({
              npi: d.npi,
              addresses: d.addresses,
            })),
            doctorVisitsNumber: quoteContext.doctorVisitsNumber,
            specialistVisitsNumber: quoteContext.specialistVisitsNumber,
            favorites: quoteContext.favorites,
            filters: quoteContext.filters,
            zip: adminContext.clientView.zip,
            county: adminContext.clientView.countyName,
          },
        },
      })
        .then((res) => {
          if (res.data?.createMedicareQuoteForClient) {
            setQuoteId(res.data.createMedicareQuoteForClient.maQuoteID);
          }
          return quoteContext.saveClientInfo({
            variables: {
              clientId: res.data?.createMedicareQuoteForClient?.clientId,
              preferredDrugs: quoteContext.preferredDrugs?.map((d) =>
                convertPreferredDrugInput({
                  dosageRxcui: d.packRxcui,
                  productRxcui: d.rxcui,
                  frequency: d.frequency,
                  purchaseFrequency: d.purchaseFrequency,
                  quantity: d.quantity,
                })
              ),
              preferredPharmacies: quoteContext.preferredPharmacies?.map(
                (p: any) => p.npi
              ),
              drugDeliveryType:
                quoteContext.drugDeliveryType as unknown as ClientDrugDeliveryType,
            },
          });
        })
        .then((res) => {
          enqueueSnackbar('Offer created', { variant: 'success' });
        })
        .catch(() => {
          enqueueSnackbar('Error creating offer', { variant: 'error' });
        });
    }
  }, [adminContext, quoteContext]);

  const isOffer = React.useMemo(() => {
    return (
      !!quoteContext.id &&
      quoteContext.id === adminContext?.clientView?.agentMedicareQuote?.quoteId
    );
  }, [adminContext?.clientView?.agentMedicareQuote, quoteContext?.id]);

  const switchToQuote = (quoteId: string) => {
    setQuoteId(quoteId);
  };

  const QuoteSelector = React.useMemo(() => {
    if (!adminContext?.clientView?.agentMedicareQuote) {
      return (
        <Typography
          className={`fs-18 bold white underline ml-20 pointer`}
          onClick={createOffer}
          variant={'h4'}
        >
          Create offer
        </Typography>
      );
    } else {
      if (
        quoteContext.id === adminContext?.clientView?.agentMedicareQuote.quoteId
      ) {
        return (
          <div className={'flex flex-align-center'}>
            <LoopRoundedIcon
              className={`${
                filtersUpdating ? 'op-7 cursor-default' : 'pointer'
              } ml-20`}
              onClick={() =>
                !filtersUpdating &&
                switchToQuote(adminContext?.clientView?.quoteId)
              }
            />
            {filtersUpdating && (
              <CircularProgress size={'small'} className={'white w-20 ml-10'} />
            )}
            <Typography
              className={`fs-12 white underline ml-20 pointer`}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${document.location.origin}?adminQuote=${adminContext?.clientView?.agentMedicareQuote?.quoteId}`
                );
                enqueueSnackbar('Copied!', { variant: 'info' });
              }}
            >
              (copy link)
            </Typography>
          </div>
        );
      } else {
        return (
          <div className={'flex flex-align-center'}>
            <LoopRoundedIcon
              className={`${
                filtersUpdating ? 'op-7 cursor-default' : 'pointer'
              } ml-20`}
              onClick={() =>
                !filtersUpdating &&
                switchToQuote(
                  adminContext?.clientView?.agentMedicareQuote?.quoteId
                )
              }
            />
            {filtersUpdating && (
              <CircularProgress size={'small'} className={'white w-20 ml-10'} />
            )}
            <Typography
              className={`fs-12 white underline ml-20 pointer`}
              onClick={() => {
                navigator.clipboard.writeText(
                  `${document.location.origin}?quote=${adminContext?.clientView?.quoteId}`
                );
                enqueueSnackbar('Copied!', { variant: 'info' });
              }}
            >
              (copy link)
            </Typography>
          </div>
        );
      }
    }
  }, [
    adminContext?.clientView?.agentMedicareQuote,
    quoteContext?.id,
    filtersUpdating,
  ]);

  if (adminContext == null) {
    if (isAdmin) {
      return (
        <Box
          sx={{
            height: '50px',
            padding: '0 18px',
            color: 'white',
          }}
        ></Box>
      );
    }
    return null;
  }

  const fullName =
    `${adminContext.clientView.firstName} ${adminContext.clientView.lastName}`.trim();

  return (
    <Box
      sx={{
        height: '50px',
        padding: '0 18px',
        color: 'white',
        background: isOffer ? '#3f3efb' : '#FB0A0A',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        display={'flex'}
        flex={1}
        alignItems={'center'}
        justifyContent={'space-between'}
        mr={3}
      >
        <Box display={'flex'}>
          <Typography
            className={'fs-18 bold white'}
            variant={'h4'}
          >{`${fullName}'s ${isOffer ? 'offer' : 'quote'}`}</Typography>
          {QuoteSelector}
        </Box>
        {!!compareOfferContext.plans.length && (
          <Typography
            className={`fs-14 white underline ml-20 pointer`}
            onClick={() => {
              document.location.href =
                environment.dashboardUrl +
                'compare-offer?clientId=' +
                adminContext.clientId;
            }}
          >
            Preview compare offer
          </Typography>
        )}
      </Box>
      <IconButton onClick={adminContext.clear}>
        <img src={'/assets/img/white-close.svg'} />
      </IconButton>
    </Box>
  );
};

export default AdminNotification;
