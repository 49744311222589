import React from "react";
import {
  Box, Button, Typography
} from '@mui/material';
import { useParams, useNavigate} from "react-router";
import { colors } from '@coverright/ui/themes';
import { CRPreloader } from '@coverright/ui/shared';
import { ComparisonContext } from '../../ComparisonContext';
import PlanDetailsContent from '../detailed-plan/components/PlanDetailsContent';
import ComparisonTable from './components/ComparisonTable';

export default function PlanComparison() {
  const {zip, countyName} = useParams<{ zip: string, countyName: string }>();
  const {maPlans: {plans, count}} = React.useContext(ComparisonContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!count) {
      navigate('/plans/' + zip + '/' + countyName)
    }
  }, [count]);

  if (!plans.length) {
    return <Box display={"flex"} flexDirection={'column'} flexGrow={1} justifyContent={"center"} alignItems={'center'} mt={'20vh'}>
      <CRPreloader />
    </Box>
  }

  return <Box sx={{mb: 30, mx: 3}}>
    <BackButtonComponent onBackClick={() => navigate(`/plans/${zip}/${countyName}`)} />

    <Typography sx={{fontSize: {xs: 20, sm: 32}, lineHeight: {xs: '25px', sm: '40px'}}}>You’re comparing  <b>{plans.length} Medicare Advantage Plans</b></Typography>

    <Box sx={{display: 'flex', gap: 4, mt: 2}}>
      <Box sx={{display: {xs: 'none', lg: 'block'} }}>
        <PlanDetailsContent plan={plans[0]}/>
      </Box>

      <Box position={'relative'} sx={{width: '100%'}}>
        {!!plans.length && <ComparisonTable plans={plans} zip={zip!} countyName={countyName!} />}
      </Box>
    </Box>
  </Box>
}

const BackButtonComponent = (props: {onBackClick: () => void}) => {

  return <Box sx={{
    position: 'sticky',
    top: 0,
    boxSizing: 'border-box',
    background: '#f5f7f6',
    zIndex: 10,
    left: 0,
    width: '100%',
    maxWidth: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }}>
    <Button
      sx={{color: colors.text.primary}}
      startIcon={<img src={"/assets/img/arrow-left.svg"}/>}
      onClick={props.onBackClick}>
      Back to plans
    </Button>
  </Box>
}
