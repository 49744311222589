import React from 'react';
import { useParams } from 'react-router';
import {
  PlanFilterContext,
  withPlanFilterProvider,
} from './components/filters/PlanFilterContext';
import PlanList from './plan-list';
import PlanListSideContent from './components/PlanListSideContent';
import ComparisonPanel from '../plan-comparison/components/ComparisonPanel';
import paths from '../../../config/router-paths';
import { Box, Container, Typography } from '@mui/material';
import { UpdateZip } from '@coverright/widgets';

const DashboardLayout = () => {
  const { zip, countyName } = useParams<{ zip: string; countyName: string }>();
  const { values: filterValues, switchZip } =
    React.useContext(PlanFilterContext);
  const [total, setTotal] = React.useState(0);

  const onUpdateZipModalClose = React.useCallback(
    (newZip?: string, newCounty?: string) => {
      if (newZip && newCounty && (newCounty !== countyName || newZip !== zip)) {
        switchZip(newZip, newCounty).then(() => {
          document.location.href = paths.plans + newZip + '/' + newCounty;
        });
      }
    },
    [zip, countyName, filterValues]
  );

  return (
    <Container maxWidth={'lg'} sx={{ mb: 30 }}>
      <Typography
        sx={{
          fontSize: { xs: 20, sm: 32 },
          lineHeight: { xs: '25px', sm: '40px' },
        }}
      >
        We’ve found <b>{total} Medicare Advantage Plans</b> for you
      </Typography>
      <UpdateZip
        zip={zip}
        county={countyName}
        onChange={onUpdateZipModalClose}
      />
      <Box
        sx={{
          display: 'flex',
          gap: 4,
          flexDirection: { xs: 'column-reverse', lg: 'row' },
        }}
      >
        <PlanListSideContent />
        <PlanList onPlansCountChange={setTotal} />
      </Box>
      {zip && <ComparisonPanel countyName={countyName!} zip={zip} />}
    </Container>
  );
};

export default withPlanFilterProvider(DashboardLayout);
