import React from "react";
import { useNavigate } from "react-router";
import { createStyles, makeStyles } from '@mui/styles';
import {
  Badge,
  Box,
  MenuItem,
  Theme,
  Typography,
  useTheme
} from '@mui/material';
import { colors } from '@coverright/ui/themes';
import { SelectInput } from '@coverright/ui/inputs';
import paths from '../../../../config/router-paths';
import { Field } from '@coverright/data-access/types/medicare';
import { areComponentPropsEqual } from '@coverright/utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sort: {
      padding: '4px 14px 2px',
      fontSize: 12,
      color: colors.text.primary,
      cursor: 'pointer',
      borderRadius: 4,
      fontWeight: 700
    },
    sortActive: {
      color: colors.custom.green.variant1,
    },
  }),
);

interface PlanListSortProps {
  onChange: (value: Field) => void,
  value: Field,
  zip?: string,
  county?: string,
  favoritesCount: number
}

const PlanListSort = (props: PlanListSortProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<any>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <>
    <Box display={'flex'} alignItems={{xs: 'flex-start', sm: 'center'}} sx={{flexDirection: {xs: 'column', sm: 'row'}}} justifyContent={'space-between'} mb={'16px'}>
      <Box display={'flex'} alignItems={'center'}>
        <Typography className={'fs-16'}>Sort by:</Typography>
        <Box ml={'8px'}>
          <SelectInput hideTick sx={{minWidth: 220, background: 'white!important'}} className={'mb-0'} value={props.value} onChange={e => props.onChange(e.target.value as Field)} >
            <MenuItem value={Field.MonthlyCost}>Lowest Cost</MenuItem>
            <MenuItem value={Field.DoctorsCoverage}>Doctors Coverage</MenuItem>
            <MenuItem value={Field.Rating}>CMS star rating</MenuItem>
          </SelectInput>
        </Box>
      </Box>
      <Box mt={{xs: 2, sm: 0}} className={'flex flex-align-center pointer'} onClick={() => navigate(paths.favorites + props.zip + '/' + props.county)}>
        <Typography className={'fs-12 medium'} color={'textPrimary'}>See your favorites</Typography>
        <div className={'w-5'} />
        <Badge color="primary" overlap={'circular'} badgeContent={props.favoritesCount} anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}>
          <img width={30} height={30} src={'/assets/img/heart.svg'}/>
        </Badge>
      </Box>
    </Box>
  </>;
}

export default React.memo(PlanListSort, areComponentPropsEqual);
