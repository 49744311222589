import { Box, Chip } from '@mui/material';
import { PlanFilterContext } from './PlanFilterContext';
import * as React from 'react';
import { DrugErrorModal, DsnpOffErrorModal, DsnpOnErrorModal } from './ErrorModals';
import * as _ from 'lodash';
import { replaceAll } from '@coverright/utils';
import { ExtraBenefit, Rating, SnpType } from '@coverright/data-access/types/medicare';

export default function FiltersBadges() {
  const filter = React.useContext(PlanFilterContext);
  const [drugErrorOpen, setDrugErrorOpen] = React.useState(false);
  const [dsnpOnErrorOpen, setDsnpOnErrorOpen] = React.useState(false);
  const [dsnpOffErrorOpen, setDsnpOffErrorOpen] = React.useState(false);

  const filters = React.useMemo(() => {
    let result: any[];
    result = filter.values?.maxOutOfPocketRangeNames?.map(v => getFilter('Max out-of-pocket: ' + v, () => filter.switchMaxOutOfPocketRanges(v))) || [];
    result = [...result, ...(filter.values?.maxCostRangeNames?.map(v => getFilter('Monthly premium: ' + v, () => filter.switchMaxCostRanges(v))) || [])];
    result = [...result, ...(filter.values?.rating?.filter(v => [Rating.R5, Rating.R4, Rating.R3, Rating.R2, Rating.R1, Rating.None].includes(v)).map(v => getFilter(('Rating: ' + v.replace('R' , '') + ' Stars').replace('NONE Stars' , 'Not rated'), () => filter.switchRating(v))) || [])];
    result = [...result, ...(filter.values?.planTypes?.map(v => getFilter('Plan type: ' + v, () => filter.switchPlanType(v))) || [])];

    if (filter.values?.SNPTypes?.includes(SnpType.DSnp)) {
      result = [...result, getFilter('Special Needs Plans', () => {
        if (filter.values?.SNPTypes.includes(SnpType.DSnp)) {
          setDsnpOffErrorOpen(true);
        } else {
          setDsnpOnErrorOpen(true);
        }
      })]
    }

    if (filter.values?.extraBenefits?.includes(ExtraBenefit.DrugCoverage)) {
      result = [...result, getFilter('Prescription Drug Coverage', () => {
        if (filter.values?.extraBenefits.includes(ExtraBenefit.DrugCoverage)) {
          setDrugErrorOpen(true)
        } else {
          filter.switchExtraBenefits(ExtraBenefit.DrugCoverage)
        }
      })]
    }

    if (filter.values?.extraBenefits?.includes(ExtraBenefit.PartBPremiumGiveback)) {
      result = [...result, getFilter('Part B Premium Reduction', () => filter.switchExtraBenefits(ExtraBenefit.PartBPremiumGiveback))]
    }

    result = [...result, ...(filter.values?.companies?.map(v => getFilter('Company: ' + v, () => filter.switchCompany(v))) || [])];

    result = [...result, ...(filter.values?.extraBenefits?.filter(b => b !== ExtraBenefit.DrugCoverage && b !== ExtraBenefit.PartBPremiumGiveback).map(v => getFilter('Extra benefit: ' + _.upperFirst(replaceAll(_.toLower(v), '_', ' ')), () => filter.switchExtraBenefits(v))) || [])];


    return result;
  }, [filter.values]);

  return <Box sx={{flexWrap: 'wrap', display: 'flex', gap: 1}}>
    <DrugErrorModal open={drugErrorOpen} onClose={(val: any) => {
      if (val) {
        filter.switchExtraBenefits(ExtraBenefit.DrugCoverage)
      }
      setDrugErrorOpen(false);
    }} />

    <DsnpOnErrorModal open={dsnpOnErrorOpen} onClose={(val: any) => {
      if (val) {
        filter.switchSNPType(SnpType.DSnp)
      }
      setDsnpOnErrorOpen(false);
    }} />

    <DsnpOffErrorModal open={dsnpOffErrorOpen} onClose={(val: any) => {
      if (val) {
        filter.switchSNPType(SnpType.DSnp)
      }
      setDsnpOffErrorOpen(false);
    }} />

    {filters}
  </Box>
}

const getFilter = (title: string, onDelete: () => void) =>
  <Chip key={title} label={title} onDelete={onDelete} deleteIcon={<img src={'/assets/img/icon-close.svg'} className={'w-8 h-8'}/>} />

